<template>
  <div>
    <register-firma ref="RegisterFirma" />
    <show-firmas ref="ShowFirmas" />
  </div>
</template>
<script>
  export default {
    components: {
      RegisterFirma: () => import('./components/RegisterFirma'),
      ShowFirmas: () => import('./components/ShowFirmas'),
    },
  }
</script>
